import React from 'react';
import { Country } from '../../../services/ReferenceApi';
import { CitizenshipStatus, DocumentType } from '../../../services/SelfCheckApi';
import { ssnFieldName, UserInfoResponseData } from '../../../services/UserApi';
import { ErrorMessages, ValidateRules, ValidationRules } from '../../../utils/forms';
import { isFutureDate, isValidDate } from '../../../utils/dates';
import { I551HelpSection, SelfCheckSsnHelpSection, UsPassportHelpSection } from '../../HelpSections';
import I766HelpSection from '../../HelpSections/I766HelpSection/I766HelpSection';
import USCISANumberHelpSection from '../../HelpSections/USCISANumberHelpSection';
import I94HelpSection from '../../HelpSections/I94HelpSection';
import ForeignPassportI551HelpSection from '../../HelpSections/ForeignPassportI551HelpSection';

// TODO: figure out why ESLint thinks this is being defined multiple times
/* eslint-disable-next-line no-shadow */
export enum SelfCheckFormPage {
  CONFIRM_IDENTITY = 0,
  CITIZENSHIP_STATUS = 1,
  DOCUMENT_SELECTION = 2,
  DOCUMENT_DETAILS = 3,
  REVIEW = 4,
}

export const selfCheckPageTitles = {
  CONFIRM_IDENTITY_INFORMATION: 'myE-Verify | Confirm Identity Information',
  EDIT_PERSONAL_INFORMATION: 'myE-Verify | Edit Personal Information',
  SELECT_CITIZENSHIP_STATUS: 'myE-Verify | Select Citizenship Status',
  US_CITIZEN_AND_NATIONAL_DOCUMENT_SELECTIION: 'myE-Verify | US Citizen & National | Document Selection',
  LAWFUL_PERMANENT_RESIDENT_DOCUMENT_SELECTION: 'myE-Verify | Lawful Permanent Resident | Document Selection',
  NONCITIZEN_AUTHORIZED_TO_WORK_DOCUMENT_SELECTION: 'myE-Verify | Alien Authorized to Work | Document Selection',
  CONFIRM_DOCUMENT_DETAILS_SSN: 'myE-Verify | Confirm Social Security Number Details',
  CONFIRM_DOCUMENT_DETAILS_US_PASSPORT: 'myE-Verify | Enter US Passport Details',
  CONFIRM_DOCUMENT_DETAILS_FOREIGN_PASSPORT: 'myE-Verify | Enter Foreign Passport Details',
  CONFIRM_DOCUMENT_DETAILS_PERMANENT_RESIDENT_CARD: 'myE-Verify | Enter Permanent Resident Card Details',
  CONFIRM_DOCUMENT_DETAILS_FORM_I94: 'myE-Verify | Enter Form I-94 Date Stamp Details',
  CONFIRM_DOCUMENT_DETAILS_FORM_I766: 'myE-Verify | Enter Form I-766 Details',
  CONFIRM_ALL_INFORMATION: 'myE-Verify | Self Check | Confirm Your Information',
  REVERIFY_ALL_INFORMATION: 'myE-Verify | Self Check | Reverify Your Information',
};

// Field Names
export const firstNameFieldName = 'firstName';
export const lastNameFieldName = 'lastName';
export const middleNameFieldName = 'middleName';
export const dobFieldName = 'dob';
export const documentTypeFieldName = 'documentType';
export const USCISANumberFieldName = 'uscisANumber';
export const i94NumberFieldName = 'i94Number';
export const foreignPassportNumberFieldName = 'foreignPassportNumber';
export const countryOfIssuanceFieldName = 'countryOfIssuance';
export const supportingDocumentTypeFieldName = 'supportingDocumentType';
export const usPassportNumberFieldName = 'usPassportNumber';
export const documentExpirationDateFieldName = 'documentExpirationDate';
export const visaNumberFieldName = 'visaNumber';
export const i766NumberFieldName = 'i766Number';
export const i551NumberFieldName = 'i551Number';
export const documentNumberProvidedFieldName = 'documentNumberProvided';

// Custom Types
// TODO: figure out why ESLint thinks this is being defined multiple times
/* eslint-disable-next-line no-shadow */
export enum SupportingDocumentType {
  USCIS_A_NUMBER = 'USCIS_A_NUMBER',
  I_94 = 'I_94',
  FOREIGN_PASSPORT = 'FOREIGN_PASSPORT',
}

// Form Page Forms
export interface SelfCheckFormDocumentSelectionFormData {
  [documentTypeFieldName]?: DocumentType;
  [USCISANumberFieldName]?: string;
  [supportingDocumentTypeFieldName]?: SupportingDocumentType;
  [i94NumberFieldName]?: string;
  [foreignPassportNumberFieldName]?: string;
  [countryOfIssuanceFieldName]?: Country['code'];
}

export type UserInfoFormData = Pick<UserInfoResponseData, 'firstName' | 'lastName' | 'middleName' | 'dob' | 'ssn'>;

export type USPassportFormData = {
  [usPassportNumberFieldName]: string;
  [documentExpirationDateFieldName]: string;
};

export type ForeignPassportFormData = {
  [foreignPassportNumberFieldName]: string;
  [countryOfIssuanceFieldName]: string;
  [documentExpirationDateFieldName]: string;
  [visaNumberFieldName]?: string;
};

export type SSNFormData = {};

export type I94FormData = {
  [documentExpirationDateFieldName]: string;
};

export type I766FormData = {
  [i766NumberFieldName]: string;
  [documentExpirationDateFieldName]: string;
};

// Radios don't support Booleans nicely, so this is a yes/no string
export type RadioBoolean = 'yes' | 'no';

export type I551FormData = {
  [documentNumberProvidedFieldName]?: RadioBoolean;
  [i551NumberFieldName]: string;
};

export type SelfCheckFormDocumentDetailsFormData =
  | USPassportFormData
  | ForeignPassportFormData
  | SSNFormData
  | I94FormData
  | I766FormData
  | I551FormData;

export type DocumentWithExpirationDate = USPassportFormData | ForeignPassportFormData | I94FormData | I766FormData;

// Global Form
export interface SelfCheckFormData {
  [SelfCheckFormPage.CONFIRM_IDENTITY]?: UserInfoFormData;
  [SelfCheckFormPage.CITIZENSHIP_STATUS]?: CitizenshipStatus;
  [SelfCheckFormPage.DOCUMENT_SELECTION]?: SelfCheckFormDocumentSelectionFormData;
  [SelfCheckFormPage.DOCUMENT_DETAILS]?: SelfCheckFormDocumentDetailsFormData;
}

// Validation Error Messages
export const FIRST_NAME_REQUIRED_ERR_MSG = 'First name is required.';
export const LAST_NAME_REQUIRED_ERR_MSG = 'Last name is required.';
export const LAST_NAME_INVALID_LENGTH_ERR_MSG = 'Last name must be 25 characters or fewer.';
export const FIRST_NAME_INVALID_LENGTH_ERR_MSG = 'First name must be 25 characters or fewer.';
export const MIDDLE_INITIAL_INVALID_LENGTH_ERR_MSG = 'Middle initial may only be 1 character.';
export const USCIS_A_NUMBER_REQUIRED_ERR_MSG = 'Please enter a USCIS A-Number.';
export const USCIS_A_NUMBER_INVALID_ERR_MSG = 'USCIS A-Number must be 9 digits and omit any letters if present.';
export const FOREIGN_PASSPORT_REQUIRED_ERR_MSG = 'Please enter a foreign passport number.';
export const FOREIGN_PASSPORT_INVALID_LENGTH_ERR_MSG =
  'Foreign Passport number must be between 6 and 12 alphanumeric characters.';
export const FOREIGN_PASSPORT_INVALID_ERR_MSG = 'Foreign Passport number may only contain alphanumeric characters.';
export const US_PASSPORT_REQUIRED_ERR_MSG = 'U.S. Passport Number is required.';
export const US_PASSPORT_INVALID_LENGTH_ERR_MSG = 'U.S. Passport Number must be 9 characters.';
export const US_PASSPORT_INVALID_ERR_MSG = 'U.S. Passport number may only contain alphanumeric characters.';
export const COUNTRY_REQUIRED_ERR_MSG = 'Please select a country of issuance.';
export const DOCUMENT_EXPIRATION_DATE_REQUIRED_ERR_MSG = 'Document Expiration Date is required.';
export const DOCUMENT_EXPIRATION_DATE_INVALID_ERR_MSG =
  'Document Expiration Date must be a valid date and format MM/DD/YYYY.';
export const DOCUMENT_EXPIRATION_DATE_TOO_EARLY_ERR_MSG = 'Document Expiration Date must be a date in the future.';
export const VISA_NUMBER_INVALID_MSG = 'Visa Number may only contain letters or numbers.';
export const VISA_NUMBER_INVALID_LENGTH_ERR_MSG = 'Visa Number must be 8 characters.';
export const CARD_NUMBER_REQUIRED_ERR_MSG = 'Card Number is required.';
export const CARD_NUMBER_INVALID_LENGTH_ERR_MSG = 'Employment Authorization Number must be 13 characters.';
export const CARD_NUMBER_INVALID_ERR_MSG =
  'Please enter valid 13 character document number with 3 letter office code, followed by 10 digits.';
export const DOCUMENT_SELECTION_REQUIRED_ERR_MSG = 'Please select a document type.';
export const SUPPORTING_DOCUMENT_SELECTION_REQUIRED_ERR_MSG = 'Please select a supporting document type.';

// Validation Lengths
export const MIDDLE_INITIAL_MAX_LENGTH = 1;
export const FIRST_NAME_MAX_LENGTH = 25;
export const LAST_NAME_MAX_LENGTH = 25;
export const FOREIGN_PASSPORT_MIN_LENGTH = 6;
export const FOREIGN_PASSPORT_MAX_LENGTH = 12;
export const US_PASSPORT_EXACT_LENGTH = 9;
export const VISA_NUMBER_EXACT_LENGTH = 8;
export const CARD_NUMBER_EXACT_LENGTH = 13;

// Validation Regex
export const USCIS_A_NUMBER_REGEX = /^[0-9]{9}$/;
export const I94_REGEX = /^[0-9A-Za-z]{11}$/;
export const I94_REQUIRED_ERR_MSG = 'Please enter an I-94 number.';
export const I94_INVALID_ERR_MSG = 'I-94 number must be 11 characters.';
export const US_PASSPORT_REGEX = /^[a-zA-Z0-9]{9}$/;
export const FOREIGN_PASSPORT_REGEX = new RegExp(
  `^[a-zA-Z0-9]{${FOREIGN_PASSPORT_MIN_LENGTH},${FOREIGN_PASSPORT_MAX_LENGTH}}$`,
);
export const VISA_NUMBER_REGEX = /^[a-zA-Z0-9]{8}$/;
export const CARD_NUMBER_REGEX = /^([a-z]{3})(\*|[0-9]{1})([0-9]{9})$/i;

export type UserInfoFieldName = keyof UserInfoFormData;
export type CitizenshipStatusFieldName = 'citizenshipStatus';
export type SelfCheckFormDocumentSelectionFieldName = keyof SelfCheckFormDocumentSelectionFormData;
export type SelfCheckFormDocumentDetailsFieldName =
  | keyof USPassportFormData
  | keyof ForeignPassportFormData
  | keyof I94FormData
  | keyof I766FormData
  | keyof I551FormData;
export type FieldName =
  | UserInfoFieldName
  | CitizenshipStatusFieldName
  | SelfCheckFormDocumentSelectionFieldName
  | SelfCheckFormDocumentDetailsFieldName;
export interface FieldDefinition {
  name: FieldName;
  label: string;
  hint?: string;
  validation?: ValidationRules;
  validate?: ValidateRules; // only used with formatted date
  errorMessages: ErrorMessages;
}
export interface SelfCheckFormDocumentSelectionFieldDefinition extends FieldDefinition {
  name: SelfCheckFormDocumentSelectionFieldName;
}

export interface SelfCheckFormDocumentDetailsFieldDefinition extends FieldDefinition {
  name: SelfCheckFormDocumentDetailsFieldName;
}

export interface SelfCheckFormUserInfoFieldDefinition extends FieldDefinition {
  name: UserInfoFieldName;
}

const selfCheckFormUserInfoFieldDefinitions: Record<UserInfoFieldName, SelfCheckFormUserInfoFieldDefinition> = {
  [firstNameFieldName]: {
    name: firstNameFieldName,
    label: 'First Name',
    errorMessages: {
      required: FIRST_NAME_REQUIRED_ERR_MSG,
      maxLength: FIRST_NAME_INVALID_LENGTH_ERR_MSG,
    },
    validation: {
      required: true,
      maxLength: FIRST_NAME_MAX_LENGTH,
    },
  },
  [lastNameFieldName]: {
    name: lastNameFieldName,
    label: 'Last Name',
    errorMessages: {
      required: LAST_NAME_REQUIRED_ERR_MSG,
      maxLength: LAST_NAME_INVALID_LENGTH_ERR_MSG,
    },
    validation: {
      required: true,
      maxLength: LAST_NAME_MAX_LENGTH,
    },
  },
  [middleNameFieldName]: {
    name: middleNameFieldName,
    label: 'Middle Initial',
    errorMessages: {
      maxLength: MIDDLE_INITIAL_INVALID_LENGTH_ERR_MSG,
    },
    validation: {
      maxLength: MIDDLE_INITIAL_MAX_LENGTH,
    },
  },
  [ssnFieldName]: {
    name: ssnFieldName,
    label: 'Social Security number',
    errorMessages: {},
  },
  [dobFieldName]: {
    name: dobFieldName,
    label: 'Date of Birth',
    errorMessages: {},
  },
};

const selfCheckFormDocumentSelectionFieldDefintions: Record<
  SelfCheckFormDocumentSelectionFieldName,
  SelfCheckFormDocumentSelectionFieldDefinition
> = {
  [USCISANumberFieldName]: {
    name: USCISANumberFieldName,
    hint: USCIS_A_NUMBER_INVALID_ERR_MSG,
    label: 'USCIS A-Number',
    validation: {
      pattern: USCIS_A_NUMBER_REGEX,
    },
    errorMessages: {
      required: USCIS_A_NUMBER_REQUIRED_ERR_MSG,
      pattern: USCIS_A_NUMBER_INVALID_ERR_MSG,
    },
  },
  [documentTypeFieldName]: {
    name: documentTypeFieldName,
    label: 'Document Type',
    errorMessages: {
      required: DOCUMENT_SELECTION_REQUIRED_ERR_MSG,
    },
  },
  [i94NumberFieldName]: {
    name: i94NumberFieldName,
    label: 'I-94 Number',
    validation: {
      pattern: I94_REGEX,
    },
    errorMessages: {
      required: I94_REQUIRED_ERR_MSG,
      pattern: I94_INVALID_ERR_MSG,
    },
  },
  [supportingDocumentTypeFieldName]: {
    name: supportingDocumentTypeFieldName,
    label: 'Choose from one of the following:',
    errorMessages: {
      required: SUPPORTING_DOCUMENT_SELECTION_REQUIRED_ERR_MSG,
    },
  },
  [foreignPassportNumberFieldName]: {
    name: foreignPassportNumberFieldName,
    label: 'Foreign Passport Number',
    hint: `Must be at least ${FOREIGN_PASSPORT_MIN_LENGTH} characters.`,
    validation: {
      pattern: FOREIGN_PASSPORT_REGEX,
      minLength: FOREIGN_PASSPORT_MIN_LENGTH,
      maxLength: FOREIGN_PASSPORT_MAX_LENGTH,
    },
    errorMessages: {
      required: FOREIGN_PASSPORT_REQUIRED_ERR_MSG,
      minLength: FOREIGN_PASSPORT_INVALID_LENGTH_ERR_MSG,
      maxLength: FOREIGN_PASSPORT_INVALID_LENGTH_ERR_MSG,
      pattern: FOREIGN_PASSPORT_INVALID_ERR_MSG,
    },
  },
  [countryOfIssuanceFieldName]: {
    name: countryOfIssuanceFieldName,
    label: 'Country of Issuance',
    errorMessages: {
      required: COUNTRY_REQUIRED_ERR_MSG,
    },
  },
};

const selfCheckFormDocumentDetailsFieldDefintions: Record<
  SelfCheckFormDocumentDetailsFieldName,
  SelfCheckFormDocumentDetailsFieldDefinition
> = {
  [documentExpirationDateFieldName]: {
    name: documentExpirationDateFieldName,
    label: 'Document Expiration Date',
    validate: {
      validDate: isValidDate,
      futureDate: isFutureDate,
    },
    errorMessages: {
      required: DOCUMENT_EXPIRATION_DATE_REQUIRED_ERR_MSG,
      validDate: DOCUMENT_EXPIRATION_DATE_INVALID_ERR_MSG,
      futureDate: DOCUMENT_EXPIRATION_DATE_TOO_EARLY_ERR_MSG,
    },
  },
  [usPassportNumberFieldName]: {
    name: usPassportNumberFieldName,
    label: 'U.S. Passport Number',
    hint: 'Must be 9 characters.',
    validation: {
      pattern: US_PASSPORT_REGEX,
      minLength: US_PASSPORT_EXACT_LENGTH,
      maxLength: US_PASSPORT_EXACT_LENGTH,
    },
    errorMessages: {
      required: US_PASSPORT_REQUIRED_ERR_MSG,
      minLength: US_PASSPORT_INVALID_LENGTH_ERR_MSG,
      maxLength: US_PASSPORT_INVALID_LENGTH_ERR_MSG,
      pattern: US_PASSPORT_INVALID_ERR_MSG,
    },
  },
  [visaNumberFieldName]: {
    name: visaNumberFieldName,
    label: 'Visa Number',
    validation: {
      pattern: VISA_NUMBER_REGEX,
      maxLength: VISA_NUMBER_EXACT_LENGTH,
      minLength: VISA_NUMBER_EXACT_LENGTH,
      // Instead of submitting as "" or null, do not include this field
      setValueAs: (value: string): string | undefined => value || undefined,
    },
    errorMessages: {
      pattern: VISA_NUMBER_INVALID_MSG,
      minLength: VISA_NUMBER_INVALID_LENGTH_ERR_MSG,
      maxLength: VISA_NUMBER_INVALID_LENGTH_ERR_MSG,
    },
  },
  [foreignPassportNumberFieldName]: selfCheckFormDocumentSelectionFieldDefintions[
    foreignPassportNumberFieldName
  ] as SelfCheckFormDocumentDetailsFieldDefinition,
  [countryOfIssuanceFieldName]: selfCheckFormDocumentSelectionFieldDefintions[
    countryOfIssuanceFieldName
  ] as SelfCheckFormDocumentDetailsFieldDefinition,
  [i551NumberFieldName]: {
    name: i551NumberFieldName,
    label: 'Permanent Resident Card Number',
    validation: {
      minLength: CARD_NUMBER_EXACT_LENGTH,
      maxLength: CARD_NUMBER_EXACT_LENGTH,
      pattern: CARD_NUMBER_REGEX,
    },
    errorMessages: {
      required: CARD_NUMBER_REQUIRED_ERR_MSG,
      minLength: CARD_NUMBER_INVALID_LENGTH_ERR_MSG,
      maxLength: CARD_NUMBER_INVALID_LENGTH_ERR_MSG,
      pattern: CARD_NUMBER_INVALID_ERR_MSG,
    },
  },
  [documentNumberProvidedFieldName]: {
    name: documentNumberProvidedFieldName,
    label: 'Is there a document number on your Permanent Resident Card?',
    errorMessages: {},
  },
  [i766NumberFieldName]: {
    name: i766NumberFieldName,
    label: 'Employment Authorization Document Number',
    validation: {
      minLength: CARD_NUMBER_EXACT_LENGTH,
      maxLength: CARD_NUMBER_EXACT_LENGTH,
      pattern: CARD_NUMBER_REGEX,
    },
    errorMessages: {
      required: CARD_NUMBER_REQUIRED_ERR_MSG,
      minLength: CARD_NUMBER_INVALID_LENGTH_ERR_MSG,
      maxLength: CARD_NUMBER_INVALID_LENGTH_ERR_MSG,
      pattern: CARD_NUMBER_INVALID_ERR_MSG,
    },
  },
};

export const fieldDefinitions = {
  ...selfCheckFormUserInfoFieldDefinitions,
  ...selfCheckFormDocumentSelectionFieldDefintions,
  ...selfCheckFormDocumentDetailsFieldDefintions,
};

export interface HelpSectionConfig {
  id: string;
  title: string;
  content: React.ReactNode;
}

export const documentSelectionHelpContent: Record<CitizenshipStatus, React.ReactNode> = {
  [CitizenshipStatus.US_CITIZEN]: [<SelfCheckSsnHelpSection />, <UsPassportHelpSection />],
  [CitizenshipStatus.US_NON_CITIZEN]: [<SelfCheckSsnHelpSection />, <UsPassportHelpSection />],
  [CitizenshipStatus.PERMANENT_RESIDENT]: (
    <>
      <SelfCheckSsnHelpSection />
      <USCISANumberHelpSection />
      <I94HelpSection />
      <ForeignPassportI551HelpSection />
      <I551HelpSection />
    </>
  ),
  [CitizenshipStatus.NONCITIZEN_AUTHORIZED_TO_WORK]: (
    <>
      <SelfCheckSsnHelpSection />
      <USCISANumberHelpSection />
      <I94HelpSection />
      <I766HelpSection />
    </>
  ),
};
